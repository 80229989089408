import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agency-copyright',
  templateUrl: './agency-copyright.component.html',
  styleUrls: ['./agency-copyright.component.scss']
})
export class AgencyCopyrightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
